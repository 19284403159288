var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"matter-preview-wrapper"},[(
      _vm.$store.state.matterStore.currentmatter &&
      !_vm.$store.state.matterStore.matterPreviewLoading
    )?_c('b-row',{staticClass:"matter-preview"},[_c('b-col',{attrs:{"cols":"12","lg":"8"}},[_c('b-card',[_c('b-row',{staticClass:"justify-content-end px-1 d-md-none"},[_c('b-row',[_c('matter-order-judgment',{staticClass:"mb-1",attrs:{"data":_vm.$store.state.matterStore.currentmatter,"type":'sm'}}),_c('matter-notes-docs',{staticClass:"ml-75 mb-1",attrs:{"data":_vm.$store.state.matterStore.currentmatter,"type":'sm'}}),_c('b-overlay',{attrs:{"show":_vm.$store.state.matterStore.matterRefreshLoading,"rounded":"sm","variant":"transparent","opacity":"0.5","blur":"2px"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-sm mb-1 ml-75",attrs:{"variant":"outline-primary","disabled":_vm.$store.state.matterStore.matterRefreshLoading},on:{"click":_vm.refreshMatter}},[_vm._v(" Refresh ")])],1)],1)],1),_c('b-row',{staticClass:"justify-content-end px-1 d-md-none"},[(_vm.$store.state.matterStore.matterRefreshLoading)?_c('p',[_vm._v(" Connecting to court... ")]):_vm._e()]),(
            _vm.$store.state.matterStore.currentmatter.hc_name.includes('ED-')
          )?_c('district-details'):_vm._e(),(
            _vm.$store.state.matterStore.currentmatter.hc_name.includes('hc-')
          )?_c('h-c-details'):_vm._e(),(
            _vm.$store.state.matterStore.currentmatter.hc_name.includes('sci')
          )?_c('s-c-details'):(
            _vm.$store.state.matterStore.currentmatter.hc_name.includes(
              'tribunal'
            )
          )?_c('tribunal-details'):_vm._e(),_c('footer-details',{attrs:{"matter":_vm.$store.state.matterStore.currentmatter}})],1)],1),_c('b-col',{staticClass:"matter-actions",attrs:{"cols":"12","lg":"4"}},[_c('b-card',[(
            Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
              'created_at'
            ) &&
            _vm.$store.state.matterStore.currentmatter.created_at != null &&
            _vm.$store.state.matterStore.currentmatter.created_at != ''
          )?_c('p',{staticClass:"p-0 m-0"},[_c('small',[_vm._v("Created At:")]),_vm._v(" "+_vm._s(_vm.getDate({ date: _vm.$store.state.matterStore.currentmatter.created_at }))+" ")]):_vm._e(),(
            Object.keys(_vm.$store.state.matterStore.currentmatter).includes(
              'updated_at'
            ) &&
            _vm.$store.state.matterStore.currentmatter.updated_at != null &&
            _vm.$store.state.matterStore.currentmatter.updated_at != ''
          )?_c('p',{},[_c('small',[_vm._v("Updated At:")]),_vm._v(" "+_vm._s(_vm.getDate({ date: _vm.$store.state.matterStore.currentmatter.updated_at }))+" ")]):_vm._e(),_c('client-button',{attrs:{"data":_vm.$store.state.matterStore.currentmatter}}),_c('matter-order-judgment',{attrs:{"data":_vm.$store.state.matterStore.currentmatter,"type":'lg'}}),_c('matter-notes-docs',{attrs:{"data":_vm.$store.state.matterStore.currentmatter}}),_c('matter-invoice',{staticClass:"mb-1",attrs:{"data":_vm.$store.state.matterStore.currentmatter}}),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75",attrs:{"variant":"danger","block":""},on:{"click":function($event){return _vm.deleteAlert(_vm.$store.state.matterStore.currentmatter._id)}}},[_vm._v(" Delete Matter ")]),_c('b-overlay',{attrs:{"show":_vm.$store.state.matterStore.matterRefreshLoading,"rounded":"sm","variant":"transparent","opacity":"0.5","blur":"2px"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}},{name:"b-toggle",rawName:"v-b-toggle.matter-edit",modifiers:{"matter-edit":true}}],staticClass:"mb-75",attrs:{"variant":"primary","disabled":_vm.$store.state.matterStore.matterRefreshLoading,"block":""},on:{"click":_vm.refreshMatter}},[_vm._v(" Refresh Matter ")])],1),(_vm.$store.state.matterStore.matterRefreshLoading)?_c('p',{staticClass:"p-0 m-0"},[_vm._v(" Connecting to court... ")]):_vm._e()],1)],1)],1):_vm._e(),(
      !_vm.$store.state.matterStore.currentmatter &&
      _vm.$store.state.matterStore.matterPreviewLoading
    )?_c('b-card',{staticClass:"text-center mt-2"},[_c('div',{staticClass:"d-flex justify-content-center my-2"},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1)]):_vm._e(),(
      !_vm.$store.state.matterStore.currentmatter &&
      !_vm.$store.state.matterStore.matterPreviewLoading
    )?_c('b-card',{staticClass:"text-center mt-2"},[_vm._v(" Please Try Again! ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }