<template>
  <section class="">
    <hr class="pb-1">
    <b-row class="justify-content-between">
      <b-col>
        <b-row class="justify-content-start pl-1" v-if="matter.hc_name.includes('hc-punjab')">
          Not Mine:
          <b-form-checkbox
            :checked="not_mine"
            class="custom-control-danger px-1"
            name="check-button"
            switch
            @change="updateNotMine"
        /></b-row>
      </b-col>
      <b-col>
        <b-row class="justify-content-end">
          On Priority:
          <b-form-checkbox
            :checked="user_priority"
            class="custom-control-danger px-1"
            name="check-button"
            switch
            @change="updateUserPriority"
        /></b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BBadge,
  BRow,
  BButton,
  BCol,
  BFormGroup,
  BFormTextarea,
  BFormCheckbox,
  BFormInput
} from 'bootstrap-vue'

export default {
  components: {
    BBadge,
    BRow,
    BButton,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BFormTextarea,
    BFormInput
  },
  props: {
    matter: {
      default: null
    }
  },
  data() {
    return {
      titleEditToggle: false,
      fileEditToggle: false,
      title: this.matter.title,
      file_no: this.matter.file_no,
      data: this.matter,
      not_mine:
        Object.keys(this.matter).includes('not_mine') &&
        this.matter['not_mine'] != null
          ? this.matter['not_mine']
          : false,
      user_priority:
        Object.keys(this.matter).includes('user_priority') &&
        this.matter['user_priority'] != null
          ? this.matter['user_priority']
          : false
    }
  },
  beforeMount() {},
  // Vinay Kumar Singh Vs ANSAL PROPERTIES & INFRASTRUCTURE LIMITED
  methods: {
    updateNotMine(checked) {
      console.log('Checked', checked)
      event.preventDefault()
      this.$store
        .dispatch('matterStore/updateTitleData', {
          ctx: this,
          matter_id: this.data._id,
          payload: {
            not_mine: checked
          }
        })
        .then((response) => {
          console.log(response)
          this.not_mine = checked
        })
    },
    updateUserPriority(checked) {
      console.log('Checked', checked)
      event.preventDefault()
      this.$store
        .dispatch('matterStore/updateTitleData', {
          ctx: this,
          matter_id: this.data._id,
          payload: {
            user_priority: checked
          }
        })
        .then((response) => {
          console.log(response)
          this.user_priority = checked
        })
    },
    updateTitle() {
      event.preventDefault()
      this.$store
        .dispatch('matterStore/updateTitleData', {
          ctx: this,
          matter_id: this.data._id,
          payload: {
            title: this.title
          }
        })
        .then((response) => {
          console.log(response)
          if (response.success) {
            this.data.title = response.data.title
            this.title = response.data.title
            this.titleEditToggle = false
          }
        })
    },
    updateFileNo() {
      event.preventDefault()
      this.$store
        .dispatch('matterStore/updateTitleData', {
          ctx: this,
          matter_id: this.data._id,
          payload: {
            file_no: this.file_no
          }
        })
        .then((response) => {
          console.log(response)
          if (response.success) {
            this.data.file_no = response.data.file_no
            this.file_no = response.data.file_no
            this.fileEditToggle = false
          }
        })
    }
  }
}
</script>
